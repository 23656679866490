import { Menu } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton/IconButton';
import logo from 'assets/img/logo512.png';
import logoFull from 'assets/img/mdoc_logo.png';
import MuiDrawer from 'components/Drawer/MUIDrawer';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks/useAppSelector';
import useBreakpointValue from 'utils/useBreakpointValue/useBreakpointValue';
import useDisclosure from 'utils/useDisclosure/useDisclosure';
import useGetUnreadChats from 'utils/useGetUnreadChats';
import useGetOutlierStatistics from '../../pages/outliers/hooks/useGetOutlierStatistics';

interface IMenuItemProps {
  title: string;
  icon: string;
  display: string;
  isActive?: boolean;
  onClick: () => void;
  outlierStats?: {
    in_review?: number;
    resolved?: number;
    pending?: number;
  };
}

const MenuItem = ({ title, icon, display, isActive, onClick, outlierStats }: IMenuItemProps) => {
  const [hoverIcon, setHoverIcon] = useState(false);
  return (
    <div
      onClick={onClick}
      className={`${isActive && 'bg-orange'} py-4 flex items-center ${
        display === 'none' ? 'justify-center' : ''
      } px-3 gap-2 cursor-pointer ${
        !isActive && 'hover:bg-hovOrange'
      } rounded-lg h-[55px] w-[90%] mb-1 group`}
      style={{
        transition: '.2s ease-in-out',
        borderColor: isActive ? '#f26522' : undefined,
      }}
      onMouseEnter={() => setHoverIcon(true)}
      onMouseLeave={() => setHoverIcon(false)}
    >
      <div
        className="flex justify-center items-center"
        style={{
          color: isActive ? '#FFF' : hoverIcon ? '#FD6E5E' : '#666',
          transition: '.2s ease-in-out',
        }}
      >
        <i className={`${icon} text-[20px]`}></i>
      </div>

      <p
        className={`flex flex-row justify-between grow text-md ${
          !isActive && 'group-hover:text-darkOrange'
        } whitespace-nowrap ${isActive ? 'text-white' : 'text-gray-800'}`}
        style={{ display, transition: '.2s ease-in-out' }}
      >
        {title === 'Outliers' && outlierStats ? (
          <div className="flex justify-between">
            {title}
            <div>
              <div
                style={{
                  color: isActive ? '#FFF' : hoverIcon ? '#FD6E5E' : '#FD6E5E',
                  transition: '.2s ease-in-out',
                  border: `1px solid ${isActive ? '#FFF' : hoverIcon ? '#FD6E5E' : '#FD6E5E'}`,
                }}
                className={`rounded-full w-6 h-6 flex justify-center font-bold items-center ${
                  outlierStats?.pending > 99 ? 'text-[10px] leading-[10px]' : 'text-xs'
                }`}
              >
                {outlierStats?.pending}
              </div>
            </div>
          </div>
        ) : (
          title
        )}
      </p>
    </div>
  );
};

interface IDashboardLayoutProps {
  children: any;
  isOpen?: boolean;
  onClose: () => void;
  onOpen: () => void;
  toggle: () => void;
}

const DashboardLayout = ({ children, isOpen, onClose, onOpen, toggle }: IDashboardLayoutProps) => {
  const user = useAppSelector((state) => state.user?.currentUser);
  const { outlierStats } = useGetOutlierStatistics({ coachId: user?.id });
  const notHealthCoach = useMemo(
    () => !user?.partnerId && user?.coachType?.name?.toLowerCase() !== 'hub ambassador',
    [user],
  );
  const menuList = useMemo(
    () =>
      [
        {
          title: 'Dashboard',
          icon: 'mDoc-status-up',
          to: 'dashboard',
          privilege: true,
        },
        {
          title: 'Members',
          icon: 'mDoc-profile-2user',
          to: 'members',
          privilege: notHealthCoach,
        },
        {
          title: 'Chat',
          icon: 'mDoc-messages-3',
          to: 'chat',
          privilege: notHealthCoach,
        },
        {
          title: 'Outliers',
          icon: 'mDoc-facemask',
          to: 'outliers',
          privilege: notHealthCoach,
        },
        {
          title: 'Appointments',
          icon: 'mDoc-calendar-tick',
          to: 'appointments',
          privilege: notHealthCoach,
        },
        {
          title: 'Forum',
          icon: 'mDoc-quote-down-circle',
          to: 'forum',
          privilege: notHealthCoach,
        },
        {
          title: 'BLA filter',
          icon: 'mDoc-filter-search',
          to: 'bla-filter',
          privilege: notHealthCoach,
        },
        {
          title: 'Referrals',
          icon: 'mDoc-data-2',
          to: 'referrals',
          privilege: notHealthCoach,
        },
        {
          title: 'Consults',
          icon: 'mDoc-monitor',
          to: 'consults',
          privilege: !!user?.partnerId,
        },
        {
          title: 'Hub Visit',
          icon: 'mDoc-location',
          to: 'hub-visit',
          privilege: !!!user?.partnerId,
        },

        // {
        //   title: 'Report & Analysis',
        //   icon: 'icon-report',
        //   to: 'report',
        // },
        // {
        //   title: 'Goal Management',
        //   icon: 'icon-target',
        //   to: 'goals',
        // },
        // {
        //   title: 'Coach',
        //   icon: 'icon-doctor',
        //   to: 'coach',
        // },
        // {
        //   title: 'Resource Library',
        //   icon: 'icon-folder',
        //   to: 'resources',
        // },
        // {
        //   title: 'Coach Group',
        //   icon: 'icon-group',
        //   to: 'group',
        // },
        // {
        //   title: 'Global Member',
        //   icon: 'icon-globe',
        //   to: 'global',
        // },
      ].filter((item) => item.privilege),
    [notHealthCoach, user],
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const mobileDrawerHandler = useDisclosure();

  const ref = useRef<boolean>(false);

  // fetch unread messages once
  useGetUnreadChats({ ref });

  const width = useBreakpointValue({
    base: isOpen ? '80%' : '0%',
    md: isOpen ? '350px' : '80px',
    lg: isOpen ? '350px' : '80px',
  });

  const isMobile = useBreakpointValue({
    base: true,
    md: false,
  });
  const [display, setDisplay] = useState(isMobile ? 'block' : 'none');

  useEffect(() => {
    if (!isMobile) {
      if (isOpen) {
        setTimeout(() => setDisplay('block'), 150);
      } else {
        setDisplay('none');
      }
    }
  }, [isOpen, isMobile]);

  const { pathname } = useLocation();

  return (
    <div className="flex w-full md:h-screen">
      {isMobile && (
        <>
          <div className="absolute pr-2 h-20 w-20 flex items-center justify-center">
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={() => {
                mobileDrawerHandler.onOpen();
                setDisplay('block');
              }}
              sx={{ mr: 2, display: { md: 'none' } }}
            >
              <Menu />
            </IconButton>
          </div>

          <MuiDrawer
            variant={isMobile ? 'temporary' : 'persistent'}
            anchor="left"
            icon={
              <div className={`p-4 w-14`}>
                <img src={logo} className="w-full " />
              </div>
            }
            isOpen={mobileDrawerHandler.isOpen}
            onClose={mobileDrawerHandler.onClose}
          >
            <div className="flex flex-col justify-between h-[85%]">
              <div className={`mt-4 flex flex-col items-center px-1`}>
                {menuList.map((item) => (
                  <MenuItem
                    key={item.title}
                    title={item.title}
                    display={display}
                    icon={item.icon}
                    isActive={pathname === `/${item?.to}`}
                    outlierStats={outlierStats}
                    onClick={() => {
                      navigate(item.to);
                      if (isMobile) mobileDrawerHandler.onClose();
                    }}
                  />
                ))}
              </div>
              <div className={`flex flex-col items-center`}>
                <MenuItem
                  title="Settings"
                  display={display}
                  icon="mDoc-setting-5"
                  isActive={pathname.includes('settings')}
                  onClick={() => {
                    navigate('settings');
                    if (isMobile) mobileDrawerHandler.onClose();
                  }}
                />
              </div>
            </div>
          </MuiDrawer>
        </>
      )}
      <div
        onMouseEnter={onOpen}
        className={`fixed md:relative w-0 md:w-[80px] bg-[#FAFAFA] border-r border-gray-100 h-screen z-30`}
        style={{
          transition: '.2s linear',
          width,
          display: isMobile ? 'none' : 'block',
        }}
      >
        <div className="flex justify-between items-center border-b border-gray-200">
          <div onClick={toggle} className={`p-4 h-[79px] ${isOpen ? 'w-[150px]' : 'w-[75px]'}`}>
            <img src={isOpen ? logoFull : logo} className="w-full " />
          </div>

          <div className="transition duration-500 mr-[-1rem] absolute right-[2px] z-50 border rounded-full w-6 h-6 flex items-center justify-center border-gray-300 bg-white">
            <i onClick={onClose} className={isOpen ? 'mDoc-arrow-left-2' : 'mDoc-arrow-right-3'} />
          </div>
        </div>

        <div className="flex flex-col justify-between h-[85%]">
          <div className={`mt-4 flex flex-col items-center px-1`}>
            {menuList.map((item) => (
              <MenuItem
                key={item.title}
                title={item.title}
                display={display}
                icon={item.icon}
                outlierStats={outlierStats}
                isActive={pathname === `/${item?.to}`}
                onClick={() => {
                  navigate(item.to);
                  if (isMobile) onClose();
                }}
              />
            ))}
          </div>
          <div className={`flex flex-col items-center`}>
            <MenuItem
              title="Settings"
              display={display}
              icon="mDoc-setting-5"
              isActive={pathname.includes('settings')}
              onClick={() => {
                navigate('settings');
                if (isMobile) onClose();
              }}
            />
          </div>
        </div>
      </div>
      <div className="w-full bg-offwhite h-screen overflow-y-auto">
        <div className="mb-20">{children}</div>
      </div>
    </div>
  );
};

export default DashboardLayout;
