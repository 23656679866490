import { useGetRequest } from 'api/useGetRequest';
import { OutlierControllerService } from 'generated';

const useGetOutlierStatistics = ({
  coachId,
  callback,
}: {
  coachId: string;
  callback?: () => void;
}) => {
  const { data, loading, refetch } = useGetRequest({
    service: OutlierControllerService.outlierControllerOutlierStatistics,
    tag: 'outlierControllerOutlierStatistics',
    payload: {
      reviewerId: coachId,
    },
    onSuccess: () => {
      callback?.();
    },
  });
  return {
    outlierStats: data,
    outlierStatsLoading: loading,
    refetch,
  };
};

export default useGetOutlierStatistics;
