/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EyeExamination } from '../models/EyeExamination';
import type { FootExamination } from '../models/FootExamination';
import type { HealthCondition } from '../models/HealthCondition';
import type { HealthProcedureSurgeriesWithRelations } from '../models/HealthProcedureSurgeriesWithRelations';
import type { LabsProcedures } from '../models/LabsProcedures';
import type { MedicationPassport } from '../models/MedicationPassport';
import type { NewUsers } from '../models/NewUsers';
import type { Pharmacy } from '../models/Pharmacy';
import type { Provider } from '../models/Provider';
import type { Users } from '../models/Users';
import type { UsersPartialExcluding_id_email_phone_ } from '../models/UsersPartialExcluding_id_email_phone_';
import type { UsersWithRelations } from '../models/UsersWithRelations';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UsersControllerService {
  /**
   * @returns any[] Array of Users model instances
   * @throws ApiError
   */
  public static usersControllerMemberActivityList({
    userId,
  }: {
    userId: string;
  }): CancelablePromise<any[]> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/MemberActivityList/{userId}',
      path: {
        userId: userId,
      },
    });
  }

  /**
   * @returns any User model instance
   * @throws ApiError
   */
  public static usersControllerAutoLogin(): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/auto-login',
    });
  }

  /**
   * @returns any[] Array of Users model instances
   * @throws ApiError
   */
  public static usersControllerCriticalMembers({
    coachId,
  }: {
    coachId: string;
  }): CancelablePromise<any[]> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/criticalMembers/{coachId}',
      path: {
        coachId: coachId,
      },
    });
  }

  /**
   * @returns any BlogPost model instance
   * @throws ApiError
   */
  public static usersControllerCreateWebinar({
    formData,
  }: {
    formData?: {
      resource?: string;
    };
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/general-image-upload',
      formData: formData,
      mediaType: 'multipart/form-data',
    });
  }

  /**
   * @returns any User model instance
   * @throws ApiError
   */
  public static usersControllerResetPassword({
    password,
  }: {
    password?: string;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/reset-password',
      query: {
        password: password,
      },
    });
  }

  /**
   * @returns any[] Array of Users model instances
   * @throws ApiError
   */
  public static usersControllerSendPasscode(): CancelablePromise<any[]> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/sendPasscode',
    });
  }

  /**
   * @returns any Profile picture uploaded successfully
   * @throws ApiError
   */
  public static usersControllerUploadProfilePicture({
    formData,
  }: {
    /**
     * multipart/form-data value.
     */
    formData: any;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/upload-profile-pic',
      formData: formData,
      mediaType: 'multipart/form-data',
    });
  }

  /**
   * @returns UsersWithRelations Array of Users model instances
   * @throws ApiError
   */
  public static usersControllerGetUsersByCoachId({
    coachId,
  }: {
    coachId: string;
  }): CancelablePromise<Array<UsersWithRelations>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/users/byCoachId/{coachId}',
      path: {
        coachId: coachId,
      },
    });
  }

  /**
   * @returns any Change password after forgot password flow
   * @throws ApiError
   */
  public static usersControllerChangePassword({
    requestBody,
  }: {
    requestBody?: {
      password: string;
      id: string;
    };
  }): CancelablePromise<{
    status?: string;
  }> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/users/change-password',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns any Users model count
   * @throws ApiError
   */
  public static usersControllerCount({ where }: { where?: any }): CancelablePromise<{
    count?: number;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/users/count',
      query: {
        where: where,
      },
    });
  }

  /**
   * @returns UsersWithRelations Users model instance
   * @throws ApiError
   */
  public static usersControllerUserDashboard(): CancelablePromise<UsersWithRelations> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/users/dashboard',
    });
  }

  /**
   * @returns UsersWithRelations Users model instance
   * @throws ApiError
   */
  public static usersControllerGetUserList({
    requestBody,
  }: {
    requestBody?: any;
  }): CancelablePromise<UsersWithRelations> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/users/getUserList',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns any Users recent health passports
   * @throws ApiError
   */
  public static usersControllerFetchUserRecentHealthPassport({
    memberId,
  }: {
    memberId?: string;
  }): CancelablePromise<{
    eyeExamination?: EyeExamination;
    footExamination?: FootExamination;
    pharmacy?: Pharmacy;
    procedure?: HealthProcedureSurgeriesWithRelations;
    provider?: Provider;
    healthCondition?: HealthCondition;
    medication?: MedicationPassport;
    lab?: LabsProcedures;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/users/health-passport',
      query: {
        memberId: memberId,
      },
    });
  }

  /**
   * @returns any Token
   * @throws ApiError
   */
  public static usersControllerLogin({
    requestBody,
  }: {
    /**
     * The input of login function
     */
    requestBody: {
      email: string;
      password: string;
      source?: 'mobile_app' | 'web';
      browserAgent?: string;
    };
  }): CancelablePromise<{
    user?: UsersWithRelations;
    accessToken?: string;
    refreshToken?: string;
  }> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/users/login',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns any Users  success
   * @throws ApiError
   */
  public static usersControllerLoginWithPhoneNumber({
    phoneNumber,
  }: {
    phoneNumber?: string;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/users/loginWithPhoneNumber',
      query: {
        phoneNumber: phoneNumber,
      },
    });
  }

  /**
   * @returns any Logout
   * @throws ApiError
   */
  public static usersControllerLogout({
    requestBody,
  }: {
    requestBody?: {
      source?: 'mobile_app' | 'web';
      browserAgent?: string;
    };
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/users/logout',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns any token refreshed
   * @throws ApiError
   */
  public static usersControllerRefreshUserToken({
    token,
    source,
  }: {
    token?: string;
    source?: 'mobile_app' | 'web';
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/users/refresh-token',
      query: {
        token: token,
        source: source,
      },
    });
  }

  /**
   * @returns any Generate OTP and send via Email or Phone number
   * @throws ApiError
   */
  public static usersControllerSendOtp({
    requestBody,
  }: {
    requestBody?: {
      email?: string;
      phone?: string;
    };
  }): CancelablePromise<{
    status?: 'success' | 'failed';
    message?: string;
  }> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/users/sendOTP',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns any Users model instance
   * @throws ApiError
   */
  public static usersControllerSocialLogin({
    requestBody,
  }: {
    requestBody?: any;
  }): CancelablePromise<{
    user?: UsersWithRelations;
    accessToken?: string;
    refreshToken?: string;
  }> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/users/socialLogin',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns any Update user password in-app
   * @throws ApiError
   */
  public static usersControllerUpdatePassword({
    requestBody,
  }: {
    requestBody?: {
      oldPassword?: string;
      newPassword?: string;
    };
  }): CancelablePromise<{
    status?: string;
  }> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/users/update-password',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns any Token
   * @throws ApiError
   */
  public static usersControllerUserLogin({
    requestBody,
  }: {
    requestBody?: any;
  }): CancelablePromise<{
    token?: string;
  }> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/users/userLogin',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns any[] Array of Users model instances
   * @throws ApiError
   */
  public static usersControllerUsersChart({
    startDate,
    endDate,
  }: {
    startDate?: string;
    endDate?: string;
  }): CancelablePromise<any[]> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/users/usersChart',
      query: {
        startDate: startDate,
        endDate: endDate,
      },
    });
  }

  /**
   * @returns any Verify the user OTP
   * @throws ApiError
   */
  public static usersControllerVerifyOtp({
    requestBody,
  }: {
    requestBody?: {
      email?: string;
      phone?: string;
      otp?: string;
      verificationType?: 'activation' | 'reset';
    };
  }): CancelablePromise<{
    status?: string;
    accessToken?: string;
    refreshToken?: string;
    user?: UsersWithRelations;
  }> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/users/verifyOTP',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static usersControllerReplaceById({
    id,
    requestBody,
  }: {
    id: string;
    requestBody?: Users;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/users/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static usersControllerUpdateById({
    id,
    requestBody,
  }: {
    id: string;
    requestBody?: UsersPartialExcluding_id_email_phone_;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/users/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns UsersWithRelations Users model instance
   * @throws ApiError
   */
  public static usersControllerFindById({
    id,
    filter,
  }: {
    id: string;
    filter?: any;
  }): CancelablePromise<UsersWithRelations> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/users/{id}',
      path: {
        id: id,
      },
      query: {
        filter: filter,
      },
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static usersControllerDeleteById({ id }: { id: string }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/users/{id}',
      path: {
        id: id,
      },
    });
  }

  /**
   * @returns any Users model instance
   * @throws ApiError
   */
  public static usersControllerCreate({
    requestBody,
  }: {
    requestBody?: NewUsers;
  }): CancelablePromise<{
    otpStatus?: any;
    user?: {
      id?: string;
      email?: string;
      phone?: string;
    };
  }> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/users',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns UsersWithRelations Array of Users model instances
   * @throws ApiError
   */
  public static usersControllerFind({
    filter,
    startDate,
    endDate,
    page,
  }: {
    filter?: any;
    startDate?: string;
    endDate?: string;
    page?: number;
  }): CancelablePromise<Array<UsersWithRelations>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/users',
      query: {
        filter: filter,
        startDate: startDate,
        endDate: endDate,
        page: page,
      },
    });
  }

  /**
   * @returns any User model instance
   * @throws ApiError
   */
  public static usersControllerUsersMigration(): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/usersMigration',
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static usersControllerUpdateByManagement({
    id,
    requestBody,
  }: {
    id: string;
    requestBody: { activeStatus: string };
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/user-update-by-management/{id}',
      body: requestBody,
      mediaType: 'application/json',
      path: {
        id,
      },
    });
  }
}
